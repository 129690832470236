import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi } from 'api/ApplicationDataApi';
import { GetApplicationDataResponse } from 'handlers/applicationData';

export const createGetApplicationDataThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/getApplicationData', async (applicationId: string, thunkApi) => {
    try {
      const applicationData: GetApplicationDataResponse = await api.getApplicationData(applicationId);

      return applicationData;
    } catch (error: any) {
      const payload = { ...(error.response?.data ?? {}), statusCode: error.responseStatus ?? 0 };
      return thunkApi.rejectWithValue(payload);
    }
  });
