import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { getRedirectPathForLogin } from 'utils/loginRedirectUtils';

interface UnwrappableAction {
  payload: any;
  meta?: any;
  error?: any;
}

export interface AsyncThunkConfig {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
}

const useDispatchWithUnwrap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dispatchWithUnwrap = async <Returned, ThunkArg, ThunkApiConfig extends AsyncThunkConfig>(
    action: AsyncThunkAction<Returned, ThunkArg, ThunkApiConfig>,
  ) => {
    const result = ((await dispatch(action)) as unknown) as UnwrappableAction;
    if (result.error) {
      if (result.payload?.statusCode === 401 || result.payload?.statusCode === 403) {
        navigate(getRedirectPathForLogin());
        return {} as Returned;
      }
    }
    return unwrapResult(result) as Returned;
  };

  return dispatchWithUnwrap;
};

export default useDispatchWithUnwrap;
